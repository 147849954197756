<template>
  <v-card>
    <p class="font-weight-medium pt-3 px-3">Contact Information</p>
    <v-row align="center" class="px-3" no-gutters>
      <v-col cols="12" xs="12" md="5" class="text-center">
        <v-avatar size="140">
          <v-img
            :src="this.$store.getters['user/user'].user.imageUrl || 'https://s3.amazonaws.com/baptist-health-sparks/wp-content/uploads/2018/10/29183342/default-profile.png'"
            aspect-ratio="1"
            alt="avatar"
            class="imgBorderRadius"
          ></v-img>
        </v-avatar>
        <v-col v-if="!avatar || saved == true" cols="12">
          <v-btn @click="visibleProfilePicture = true" class="primary" depressed small>Change Picture</v-btn>
        </v-col>
      </v-col>
      <v-col cols="12" sm="12" md="7" :class="{'pl-4': !$vuetify.breakpoint.mdAndDown}">
        <v-form ref="basicInfo">
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model="email"
                :rules="required"
                label="Email"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-combobox
                v-model="province"
                :items="provinces"
                :rules="required"
                @change="populateCities()"
                label="Province"
                menu-props="auto"
                outlined
                dense
              ></v-combobox>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="city"
                :items="cities"
                :rules="required"
                label="City or Municipality"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <div v-if="this.$store.getters['user/user'].user.isLawyer">
      <v-divider></v-divider>
      <v-row no-gutters>
        <v-col cols="12">
          <ProfessionalInfo />
        </v-col>
      </v-row>
    </div>
    <v-card-actions class="pa-3 px-3 lightBlack action-bottom-border">
      <v-spacer></v-spacer>
      <v-btn
        :loading='savingProfile'
        @click="updateInfo"
        color="primary"
        depressed
        small
      >Save Profile</v-btn>
    </v-card-actions>
    <ProfilePictureModal
      :visible="visibleProfilePicture"
      :image="this.$store.getters['user/user'].user.imageUrl"
      @close="visibleProfilePicture = !visibleProfilePicture"
    />
  </v-card>
</template>

<script>
import ProfessionalInfo from '../SettingsComponents/Profile/ProfessionalInfo'
import ProfilePictureModal from './Profile/ProfilePictureModal'
import Philippines from 'philippines'

export default {
  components: {
    ProfessionalInfo,
    ProfilePictureModal
  },
  data () {
    return {
      visibleProfilePicture: false,
      savingProfile: false,
      avatar: null,
      saving: false,
      saved: false,
      cities: [],
      provinces: [],
      required: [(v) => !!v || 'This field is required.']
    }
  },
  methods: {
    getProvinces () {
      for (let i = 0; i < Philippines.provinces.length; i++) {
        this.provinces.push(Philippines.provinces[i].name)
      }
      this.provinces.sort()
    },
    populateCities () {
      this.cities = []
      let selectedProvinceKey
      for (let i = 0; i < Philippines.provinces.length; i++) {
        if (Philippines.provinces[i].name === this.province) {
          selectedProvinceKey = Philippines.provinces[i].key
        }
      }
      for (let x = 0; x < Philippines.cities.length; x++) {
        if (Philippines.cities[x].province === selectedProvinceKey) {
          this.cities.push(Philippines.cities[x].name)
        }
      }
    },
    uploadImage () {
      this.saving = true
      this.$root.$emit('updatePicture')
      setTimeout(() => this.savedAvatar(), 1000)
    },
    savedAvatar () {
      this.saving = false
      this.saved = true
    },
    updateInfo () {
      if (this.$refs.basicInfo.validate()) {
        this.savingProfile = true
        this.$store.dispatch('user/UPDATE_INFORMATION')
          .then(res => {
            this.$swal({
              type: 'success',
              title: res.message,
              showConfirmButton: false,
              timer: 2000
            })
          })
          .catch((error) => {
            this.$swal({
              type: 'error',
              text: error.response.data,
              showConfirmButton: false,
              timer: 2000
            })
          })
          .finally(() => {
            this.savingProfile = false
          })
      }
    }
  },
  computed: {
    email: {
      get () {
        return this.$store.getters['user/user'].user.email
      },
      set (value) {
        this.$store.commit('user/SET_EMAIL', value)
      }
    },
    province: {
      get () {
        return this.$store.getters['user/user'].user.province
      },
      set (value) {
        this.$store.commit('user/SET_PROVINCE', value)
      }
    },
    city: {
      get () {
        return this.$store.getters['user/user'].user.city
      },
      set (value) {
        this.$store.commit('user/SET_CITY', value)
      }
    }
  },
  created () {
    this.getProvinces()
    this.populateCities()
  }
}
</script>
