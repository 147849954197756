<template>
  <div class="pa-3">
    <p class="font-weight-medium">Professional Information</p>
    <v-row align="center" no-gutters>

      <v-col cols="12">
        <v-text-field
          v-model="lawOffice"
          label="Law Office"
          hint="Leave blank if none"
          required
          outlined
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="12" style="margin-top: -24px;">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-checkbox v-model="lawyerSpecialization" value="General Practitioner" label="General Practitioner" :disabled="isGeneral" @change="isDisabled = !isDisabled" v-on="on"></v-checkbox>
          </template>
          <span>Once ticked, you cannot select other specialization.</span>
        </v-tooltip>
      </v-col>

      <v-col cols="12">
        <v-select
          v-model="lawyerSpecialization"
          @change="limiter"
          :disabled="isDisabled"
          :items="specialization"
          label="Specialization"
          multiple
          chips
          outlined
        ></v-select>
      </v-col>

      <v-col cols="12">
        <v-textarea
          v-model.lazy="description"
          label="Describe yourself"
          counter="1000"
          outlined
          dense
          auto-grow
        ></v-textarea>
      </v-col>

    </v-row>
  </div>
</template>

<script>
import specializationMixin from '@/mixins/specializationMixin'

export default {
  mixins: [
    specializationMixin
  ],
  data () {
    return {
      dialog: false,
      isGeneral: false,
      isDisabled: false
    }
  },
  computed: {
    lawOffice: {
      get () {
        return this.$store.getters['user/user'].user.lawOffice
      },
      set (value) {
        this.$store.commit('user/SET_LAW_OFFICE', value)
      }
    },
    lawyerSpecialization: {
      get () {
        return this.$store.getters['user/user'].user.specialization
      },
      set (value) {
        this.$store.commit('user/SET_SPECIALIZATION', value)
      }
    },
    description: {
      get () {
        return this.$store.getters['user/user'].user.description
      },
      set (value) {
        this.$store.commit('user/SET_DESCRIPTION', value)
      }
    }
  },
  methods: {
    isGeneralPractitioner () {
      if (this.lawyerSpecialization[0] === 'General Practitioner') {
        this.isDisabled = true
      }
      else if (this.lawyerSpecialization[0] !== 'General Practitioner') {
        this.isGeneral = true
      }
    },
    limiter (e) {
      if (e.length > 0) {
        this.isGeneral = true
      }
      else if (e.length === 0) {
        this.isGeneral = false
      }
      if (e.length > 3) {
        this.$swal({
          type: 'error',
          text: 'You can only select of 3 Specializations'
        })
        e.pop()
      }
    },
  },
  created () {
    this.isGeneralPractitioner()
  }
}
</script>
