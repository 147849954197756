export default {
  data () {
    return {
      specialization: [
        'Admiralty and Maritime Law',
        'Civil Law',
        'Constitutional Law',
        'Corporate Law',
        'Criminal Law',
        'Environmental Law',
        'Family Relations Law',
        'International Law',
        'Labor Law',
        'Legal Medicine',
        'Litigation',
        'Political Law',
        'Real Estate Law',
        'Taxation Law'
      ]
    }
  }
}
