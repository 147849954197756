<template>
  <v-dialog v-model="show" width="400" persistent>
    <v-sheet>
      <input
        ref="input"
        type="file"
        name="image"
        accept="image/*"
        style="display: none;"
        @change="setImage"
      />
      <v-hover>
        <v-col cols="12" class="pb-0" slot-scope="{ hover }">
          <v-img v-if="!imgSrc" @click="launchFilePicker" :src=" image ? image : 'https://wolper.com.au/wp-content/uploads/2017/10/image-placeholder.jpg'">
            <v-expand-transition>
              <div
                v-if="hover"
                class="headline d-flex transition-fast-in-fast-out blue-grey darken-4 v-card--reveal display-3 white--text"
                style="height: 100%;"
              >
                Click to Change
              </div>
            </v-expand-transition>
          </v-img>
          <vue-cropper
            v-show="imgSrc"
            ref="cropper"
            :aspect-ratio="1"
            :viewMode="1"
            :zoomOnWheel="false"
            :background="false"
            :src="imgSrc"
            preview=".preview"
          />
        </v-col>
      </v-hover>
      <v-card-actions class="pa-3">
        <v-btn
          @click="closeModal"
          small
          text
        >Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="imgSrc"
          @click="launchFilePicker"
          text
          small
        >Choose another</v-btn>
        <v-btn
          v-if="imgSrc"
          @click="uploadProfilePicture"
          :disabled="!imgSrc"
          :loading="isUploading"
          color="primary"
          depressed
          small 
        >Upload Image</v-btn>
      </v-card-actions>
    </v-sheet>
  </v-dialog>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  components: {
    VueCropper
  },
  props: [
    'visible',
    'image'
  ],
  data () {
    return {
      isUploading: false,
      file: '',
      imgSrc: ''
    }
  },
  methods: {
    launchFilePicker () {
      this.$refs.input.click()
    },
    setImage (e) {
      if (e.target.files[0]) {
        this.file = e.target.files[0]
      }

      if (this.file.type.indexOf('image/') === -1) {
        alert('Please select an image file')
        this.imgSrc = ''
        return
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.imgSrc = event.target.result
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(this.file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    uploadProfilePicture () {
      this.isUploading = true
      // This is a polyfill for Microsoft Edge Browser
      if (!HTMLCanvasElement.prototype.toBlob) {
        Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
          value: function (callback, type, quality) {
            let canvas = this
            let binStr = atob(canvas.toDataURL(type, quality).split(',')[1])
            let len = binStr.length
            let arr = new Uint8Array(len)

            for (let i = 0; i < len; i++) {
              arr[i] = binStr.charCodeAt(i)
            }

            callback(new Blob([arr], { type: type || 'image/png' }))
          }
        })
      }

      this.$refs.cropper.getCroppedCanvas().toBlob(async (blob) => {
        const formData = new FormData()
        formData.append('avatar', blob)

        this.$store.dispatch('user/UPDATE_PROFILE_PICTURE', formData)
          .then(() => {
            this.$swal({
              type: 'success',
              text: 'The profile picture was successfully changed.',
              confirmButtonColor: this.$vuetify.theme.primary,
              onOpen: () => { document.activeElement.blur() }
            })
            this.show = false
            this.isUploading = false
            this.imgSrc = ''
            this.file = ''
          })
          .catch(() => {
            this.isUploading = false
            this.$swal({
              type: 'error',
              title: 'There is an error going on in the server!'
            })
          })
      }, 'image/jpeg', 0.3)
    },
    closeModal () {
      this.show = false
      this.imgSrc = ''
      this.file = ''
    }
  },
  computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  }
}
</script>

<style scoped>
  .v-card--reveal {
    cursor: pointer;
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .8;
    position: absolute;
    width: 100%;
  }
</style>
